.footer {
    position: absolute;
    width: 100%;
    padding: 10px 10px 0 10px;
    background-color: #553c2a;
    margin-top: 15px;
    margin-bottom: 0px;
    color: white;
    bottom: 0;
}

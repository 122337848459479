.red-social{
    background-color: tomato;
    width: 84px;
    height: 84px;
    scale: 75%;
    border-radius: 10px;
}
.red-social.fb:hover{
    background-color: #4267b2;
}

.red-social.ig:hover{
    background-color: #c73998;
}
.margen {
    margin-top: 50px;
    margin-bottom: 50px;

}

.card-img-top-profile{
  border-radius: 100px;
  width: 80%;
  align-self: center;
}

.card{
    width: 200px;
    padding-top: 25px;
    border-radius: 20px;
    height: 330px;
}

.card-title{
    padding-bottom: 5px;
}

.col-md-4.gabej{
    text-align: center;
}
.navbar-custom { 
    background-color: #553c2a; 
  }

.btn.btn-style{
  background-color: #553c2a;
}

.btn.btn-style:hover{
  background-color: #6b4830;
}
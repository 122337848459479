.jumbotron {
    background-color: white;
    color: white;
    margin-top: 0px;
    margin-bottom: 0px;
     }
  
  
.btn {
    background-color:#c77324; }
  
.btnS {
    background-color: #0377fc; }
  
.btnT {
    background-color: transparent; }
  
.cardC {
    margin-top: 5px;
    margin-bottom: 0px;
    margin-left: 5px;
}
.card-img-top-profile{
  border-radius: 50%;
}

.btn-reserva {
    width: 20%;
    border-radius: 10px;
    align-self: center;
}
  
.footer-address-icon {
    padding-right: 10px; }
  
.link {
    color: white; }

.banner {
    margin-top: 10px;
    margin-bottom: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
  }
.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 15%;
  }
      
.tab-content {
  padding-top: 20px; 
}
  
.ca {
  margin-top: 50px;
  margin-bottom: 50px; }
  
  .margenb {
    margin-top: 0px;
    margin-bottom: 10px; }
    
    .contenedor{
        margin: 8%;
        /*margin: auto;
        width: 80%;*/
    }

.fa {
  padding: 20px;
  font-size: 50px;
  width: 100px;
  text-align: center;
  text-decoration: none;
}
    
/* Add a hover effect if you want */
.fa-facebook:hover {
  opacity: 0.7;
  color: #4267b2;
}

.fa-instagram:hover {
  opacity: 0.7;
  color: #c73998;
}

.conte{
  height: 100%;
}